<template>
  <div id="app">
    <div
      v-if="$root.ready && overflow"
      class="ready-overflow"
      @click="openWebsite()"
    >
      <button
        class="btn btn-outline-primary text-uppercase"
        @click="openWebsite()"
      >
        Buka Undangan
      </button>
    </div>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="agw-content bg-white"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro id="intro" />

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7890.559485458095!2d115.2386073!3d-8.5690776!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23e7f97ff9a39%3A0x877e59489725ff7c!2sJl.%20Kamboja%20No.22%2C%20Sedang%2C%20Kec.%20Abiansemal%2C%20Kabupaten%20Badung%2C%20Bali%2080352!5e0!3m2!1sen!2sid!4v1607254225226!5m2!1sen!2sid"
        directionUrl="https://www.google.com/maps/dir//Jl.+Kamboja+No.22,+Sedang,+Kec.+Abiansemal,+Kabupaten+Badung,+Bali+80352/@-8.5690776,115.2386073,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2dd23e7f97ff9a39:0x877e59489725ff7c!2m2!1d115.2386073!2d-8.5690776!3e0"
        :location="location"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <section class="footer bg-white pad-y-20" ref="footer">
      <div class="font-weight-lighter">
        &copy; {{ $moment().format("YYYY") }}. Made by
        <a href="https://ceremonyku.com">ceremonyku</a>.
      </div>
    </section>

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/wisnu-gektry/SKP_8709_h3mQfkCd6l9J.jpg?tr=w-800";
const ImageHero2 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/wisnu-gektry/SKP_8718_bKjVmvxtz1VE.jpg?tr=w-800";
const ImageHero3 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/wisnu-gektry/SKP_8526_VXbnNxKWkYLE.jpg?tr=w-800";

import Audio from "@/assets/audio/marry-your-daughter.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
  },
  data() {
    return {
      title: "Pawiwahan",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location: "Jl. Kamboja No 22 Br. Sigaran Desa Sedang Kec. Abiansemal - Kab. Badung",
      // date: "2020-04-05",
      time: {
        start: this.$moment("2020-12-31 13:00"),
        end: "Selesai",
      },
      wedding: {
        man: {
          name: "I Made Wisnu Prayoga, S. Ars.",
          surname: "Wisnu",
          parents: "I Ketut Dharma & Dra. Ni Wayan Sudri",
          description: "Putra kedua",
          address: "Br. Sigaran, Desa Sedang, Kec. Abiansemal - Kab. Badung",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/wisnu-gektry/SKP_8628_7UiMRSv0Wuz5.jpg?tr=w-400",
        },
        woman: {
          name: "I Gusti Ayu Komang Trianawati, S. Pd.",
          surname: "Gek Try",
          parents: "I Gusti Nyoman Sentanu, S. Pd. & I Gusti Ayu Parwati",
          address: "Br. Kelodan, Desa Punggul, Kec. Abiansemal - Kab. Badung.",
          description: "Putri ketiga",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/wisnu-gektry/SKP_8603_q66rNB_FBRD9.jpg?tr=w-400",
        },
      },
      mepandes: [],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true,
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false,
      },
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  },
};
</script>
